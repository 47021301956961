import { InputTextField, InputTextFieldProps } from '@almbrand/inputtextfield';
import { ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export interface InputTextFieldComponentProps extends InputTextFieldProps<ThemeUnion> {
	register: UseFormRegister<FieldValues>;
}

export const InputTextFieldComponent: React.FC<InputTextFieldComponentProps> = ({ register, ...props }) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];

	const updatedComponentProps = {
		...props,
		...register(props.name, {
			required: props.required,
			pattern: new RegExp(props.pattern),
		}),
		icon: { iconProp: { themeName } } as any,
	};
	return <InputTextField {...updatedComponentProps} />;
};
