import { FormWrapper } from '@almbrand/formwrapper';
import { Component } from 'components/Component';
import { useFormSubmit } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import style from './PageTemplate.module.scss';

export interface PageTemplateProps {
	pageData?: StepBlock;
}
export const PageTemplate: React.FC<PageTemplateProps> = ({ pageData }) => {
	const { title, fields, contentId: stepContentId } = pageData || {};

	const methods = useForm({ mode: 'onTouched' });
	const {
		handleSubmit,
		formState: { errors, isValid },
		register,
	} = methods;

	const { onSubmit } = useFormSubmit();

	const mapComponents = () => {
		return fields.items.map((item, index) => {
			// Map uniqueIdentifier?.uid to content.name
			const content = {
				...item.content,
				name: item.content.uniqueIdentifier?.uid,
			};

			const { contentId, contentType, title } = content;

			const updatedComponentProps = {
				index: index,
				stepContentId: stepContentId,
				register: register,
				hasError: !!errors?.[content.name],
				isValid: isValid,
				submitStep: () => handleSubmit(onSubmit)(),
				...content,
			};

			return (
				<Component
					key={contentId}
					{...updatedComponentProps}
				/>
			);
		});
	};

	return (
		<FormProvider {...methods}>
			<FormWrapper onSubmit={handleSubmit(onSubmit)}>
				<div className={style.PageTemplate}>
					<h1>{title}</h1>
					{mapComponents()}
				</div>
			</FormWrapper>
		</FormProvider>
	);
};
