import { Iframe } from '@almbrand/iframe';
import { LoadingSpinner } from '@almbrand/loadingspinner';
import { useEffect, useRef, useState } from 'react';
import style from './IframeComponent.module.scss';

export interface IframeComponentProps {
	title: string;
	source: string;
	contentId: number | string;
	hight?: string;
	width?: string;
}

export const IframeComponent: React.FC<IframeComponentProps> = ({
	title,
	source,
	contentId,
	hight = '100%',
	width = '100%',
}) => {
	const [iframeLoaded, setIframeLoaded] = useState(false);

	const wrapper = useRef(null);

	useEffect(() => {
		// there's duplicate id further up the hierarchy
		const iframe = document.getElementById(contentId + 'iframe') as HTMLIFrameElement;

		if (!iframe?.height || iframe.height === '100%') {
			return;
		}

		const observer = new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				if (mutation.type === 'attributes') {
					wrapper.current.style.height = iframe.height + 'px';
				}
			});
		});

		observer.observe(iframe, {
			attributes: true,
		});
	}, [iframeLoaded]);

	return (
		<div
			className={style.IframeComponent__wrapper}
			ref={wrapper}
		>
			<div className={style.IframeComponent__content}>
				{!iframeLoaded && (
					<div className={style.IframeComponent__spinner}>
						<LoadingSpinner
							color='primary'
							style='slow'
							type='dottedCircle'
						/>
					</div>
				)}
				<Iframe
					id={contentId + 'iframe'}
					src={source}
					title={title}
					onIframeLoaded={() => setIframeLoaded(true)}
					width={width}
					height={hight}
				/>
			</div>
		</div>
	);
};
