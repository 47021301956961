import { Header } from '@almbrand/header';
import { NavLink } from 'react-router-dom';

export const HeaderComponent: React.FC<HeaderReference> = ({
	logoImage,
	logoDarkImage,
	logoUrl,
	loginLabelText,
	headerLinks,
	showLoginButton,
}) => {
	const mapNavigationLinks = (links: Link[]) =>
		links
			.map((link) => ({
				text: link?.text ?? '',
				title: link?.title ?? '',
				url: link?.url ?? '',
				target: link?.target ?? '',
				isExternalLink: link?.isExternalLink ?? false,
				useVueRoute: link?.useVueRoute ?? false,
			}))
			.filter((link) => link.text && link.url);

	return (
		<Header
			logoUrl={logoImage?.url ?? ''}
			logoLink={logoUrl?.url ?? ''}
			navigationLinks={headerLinks?.length > 0 && mapNavigationLinks(headerLinks)}
			buttonProps={
				showLoginButton && {
					buttonType: 'personal',
					children: loginLabelText ?? 'Add TEXT HERE',
					size: 'small',
					linkButtonSettings: {
						href: '#',
					},
					// icon: {
					// 	src: logoDarkImage.url, //use correct node from optimizly later
					// 	altText: 'logout',
					// },
				}
			}
			renderChildren={(link: Link): JSX.Element => (
				<NavLink
					key={link?.url}
					to={link?.url}
					title={link?.title}
				>
					{link?.text}
				</NavLink>
			)}
		/>
	);
};
