import { SnapScroller } from '@almbrand/snapscroller';
import { PageTemplate } from 'components';
import { usePageParam } from 'hooks';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addPage, incrementIndex, resetIndex, updateLast } from 'store/slices/pageListSlice';
import pageTemplateStyle from '../PageTemplate/PageTemplate.module.scss';

interface ActiveContentArea {
	name: keyof ContentAreas;
	contentData: ContentData;
}

export interface MultiplePageTemplateProps {
	pageModel: PageModel;
}

export const MultiplePageTemplate: React.FC<MultiplePageTemplateProps> = memo(({ pageModel }) => {
	const [navigationIndex, setNavigationIndex] = useState(0);
	const calculatorForm = useAppSelector((state) => state.calculatorForm);

	const pages = useAppSelector((state) => state.pages);

	const { contentArea } = pages;
	const dispatch = useAppDispatch();

	const { setPageParam, getPageParam } = usePageParam();

	useEffect(() => {
		// Get the active content area
		const activeContentArea = getActiveContentArea();
		if (!activeContentArea) return; // Early return if no active content area

		const { name, contentData } = activeContentArea;
		const { sectionIndex, pageIndex } = contentData ?? {};

		const page = pageModel[name]?.items[sectionIndex]?.content.steps.items[pageIndex]?.content;
		const sectionLenght = pageModel[name]?.items.length;
		const pageLenght = pageModel[name]?.items[sectionIndex]?.content.steps.items.length;

		if (page) {
			dispatch(
				addPage({
					contentArea: name,
					page: page,
				})
			);

			setPageParam(page.contentId.toString().replace('/', ''));

			//Check if last page/step
			if (pageIndex === pageLenght - 1) {
				dispatch(incrementIndex({ contentArea: name, whatIndex: 'section' }));
				dispatch(resetIndex({ contentArea: name, whatIndex: 'page' }));
			}
			//Check if it's the last section in contentArea
			if (sectionIndex === sectionLenght - 1 && pageIndex === pageLenght - 1) {
				dispatch(updateLast({ contentArea: name, lastPage: true }));
				dispatch(updateLast({ contentArea: name, lastSection: true }));
			}
		}
	}, [Object.keys(calculatorForm).length]);

	//update SnapScroller index
	useEffect(() => {
		const indexOfActivePage = getIndexOfActivePage();
		if (indexOfActivePage !== -1) {
			setNavigationIndex(getIndexOfActivePage());
			return;
		}
	}, [getPageParam()]);

	const getActiveContentArea = (): ActiveContentArea | undefined => {
		return Object.keys(contentArea)
			.map((key: keyof ContentAreas) => {
				const { pageLast, sectionLast, sectionIndex } = contentArea[key].content;

				// Skip content areas where both sectionLast and pageLast are true
				if (sectionLast && pageLast) return undefined;

				const items = pageModel[key]?.items;

				// Ensure there are valid items in the page model and the content steps are available
				if (items?.length && items[sectionIndex]?.content.steps.items.length) {
					return {
						name: key,
						contentData: contentArea[key].content,
					};
				}

				return undefined;
			})
			.find((contentAreaObject) => contentAreaObject !== undefined) as ActiveContentArea | undefined;
	};

	const mapActivePages = () => {
		return colectActivePages().map((page) => (
			<PageTemplate
				key={page.contentId}
				pageData={page}
			/>
		));
	};

	const colectActivePages = () => {
		const activePages = [] as ActivePage[];
		Object.keys(contentArea).forEach((key: keyof ContentAreas, index) =>
			activePages.push(...contentArea[key].content.activePages)
		);

		return activePages;
	};

	const getIndexOfActivePage = (): number => {
		const page = colectActivePages().find((page) => page.contentId === parseInt(getPageParam()));

		return page?.navigationIndex;
	};

	return (
		<SnapScroller
			activeElement={navigationIndex}
			targetClass={pageTemplateStyle.PageTemplate}
		>
			{mapActivePages()}
		</SnapScroller>
	);
});

MultiplePageTemplate.displayName = 'MultiplePageTemplate';
