import { Button, ButtonProps } from '@almbrand/button';
export interface ButtonComponentProps extends ButtonProps {
	label: string;
	isValid: boolean;
}
export const ButtonComponent: React.FC<ButtonComponentProps> = ({ ...props }) => {
	return (
		<Button
			{...props}
			type='submit'
			disabledWithSubmit={!props.isValid}
			disabled={!props.isValid}
		>
			{props.label}
		</Button>
	);
};
