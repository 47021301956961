import { ContentPage, ProductCalculatorPage } from 'components';
import { PageContext } from 'context/PageContext';
import { memo, useContext } from 'react';

export const pages = {
	StartPage: ContentPage,
	ContentPage: ContentPage,
	ProductCalculatorPage: ProductCalculatorPage,
};

export const PageType: React.FC<PageModel> = memo(() => {
	const pageContext = useContext(PageContext);
	const pageType = pageContext.pageType;

	if (typeof pages[pageType] === 'undefined') {
		console.error(`Page type [${pageType}] is missing a React Component. Check /components/PageType.tsx`);
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					border: '20px red solid',
					fontSize: 'larger',
					fontWeight: 'bold',
					boxSizing: 'border-box',
				}}
			>{`Page type [${pageType}] is missing a React Component. Check /components/PageType.tsx`}</div>
		);
	}

	const DynamicPageTemplate = pages[pageType];

	return <DynamicPageTemplate />;
});

PageType.displayName = 'PageType';
